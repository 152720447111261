import React from "react";
import logo from '../header/logo.png'
import xicon from './xicon.svg'
import eu from '../../../logos/eu.jpg'

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            background:'#095a4f'
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0" style={{top:'-40px',position:'relative'}}>
                <div className="footer-nav-wrap text-white">
                  <img
                    src={logo}
                    alt="footer logo"
                    width="120"
                    className="img-fluid mb-3"
                  />
                  <p>
                  Redefining the limits of Minimally Invasive Surgery
                  </p>

                  <div className="social-list-wrap" >
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="https://www.linkedin.com/showcase/palpable-project/" target="_blank" title="Linkedin">
                          <span className="ti-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                       
                        <a href="https://twitter.com/palpableproject" target="_blank" title="Twitter">
                          <span> <img src={xicon} style={{width:'14px',marginRight:'0px',positon:'relative',marginTop:'3px'}}></img></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.youtube.com/@palpable_project" target="_blank" title="Youtube">
                          <span className="ti-youtube"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                
                  <hr style={{backgroundColor:'whitesmoke'}}/>
                  <img src={eu} style={{width:'50px',border:'2px solid whitesmoke'}}></img>
                  <p>This project has received funding from the European Union’s Horizon Europe programme under Grant Agreement N°101092518.</p>
                  <hr style={{backgroundColor:'whitesmoke'}}/>

                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
               
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                     
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">PALPABLE sitemap</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#about">Home</a>
                        </li>
                        <li className="mb-2">
                          <a href="/project">Project</a>
                        </li>
                        <li className="mb-2">
                          <a href="/partners">Partners</a>
                        </li>
                        <li className="mb-2">
                          <a href="/news">Newsroom</a>
                        </li>
                        <li className="mb-2">
                          <a href="/outcomes">Outcomes</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Project Info</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                        Starting date: 1st January 2023
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          Duration: 48 months
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                        Coordinator: TWI Hellas
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                        Consortium: 10 partners from 7 European countries
                        </li>
                        <li>More PALPABLE info at <a href='https://cordis.europa.eu/project/id/101092518'>CORDIS</a></li> 
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                 
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
