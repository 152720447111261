import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image10 from "../../logos/photos/image10.png"
import image11 from "../../logos/photos/111.png"
import image12 from "../../logos/photos/222.png"

import image13 from "../../logos/photos/333.png"
import image14 from "../../logos/photos/444.png"
import image15 from "../../logos/photos/555.jpg"
import icon2 from "../../logos/photos/1716886432933.jpeg"

import eaes2 from "../../logos/photos/EAES2.jpeg"
import eaes3 from "../../logos/photos/EAES3.jpeg"
import { Helmet,HelmetProvider} from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';

export default function EAES() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_project_kicks_off_with_a_bang');
  }
  function handleClick2() {

    navigate('/');
  }
  return (
    <Layout>
      <HelmetProvider>
       <Helmet>
     
        <meta property="og:title" content="Optical fibre sensors: From oil rigs to minimally invasive surgery"  />
        <meta property="title" content="Optical fibre sensors: From oil rigs to minimally invasive surgery"  />
        <meta name="description" content="Optical fibre sensors: From oil rigs to minimally invasive surgery"   />  
        <meta property="og:description" content="Optical fibre sensors: From oil rigs to minimally invasive surgery"  />
        <meta name="theme-color" content="#008f68" />
        </Helmet></HelmetProvider>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                Optical fibre sensors: From oil rigs to minimally invasive surgery

                 </h3>
                 <div className="col-md-5" style={{float:'right'}}>
              <div className="about-content-right">
                <img
                  src={image10}
                  style={{borderRadius:'10px',height:'250px',float:'right',marginTop:'20px'}}
                  alt="about us"
                  className="img-fluid"
                /> 
                  <figcaption style={{fontSize:'10px',textAlign:'justify',marginLeft:'30px',top:'6px',position:'relative',color:'#095a4f'}}>Figure 1. The structure of a typical single core, single mode, telecommunications optical fiber. In the center, one finds the optical fiber core (Φ8 μm), then the optical fiber cladding (Φ125 μm), and finally the protective (and removable) acrylate jacket (Φ250 μm)</figcaption>
              </div>
            </div>
             <p>Optical fibres are commonly associated with the telecommunications industry, and rightly so! From the
               commercialization of the first optical fibre data transmission systems back in the 1980s with data transmission rates of up to 45Mbit/s, 
               to today’s systems with capabilities of transmitting up to ~23Pbit/s over a single multi-core optical fibre, the optical fibre remains the 
               indisputable king of modern worldwide telecommunications. However, in the ‘commercial shadow’ of the telecommunications industry, optical fibres 
               have prospered in the last 30 years in another application field: Sensing.
</p>
<p>Optical fibre sensors can be found nowadays in more places than one could possibly imagine. From spacecraft gyroscopes, to nuclear reactor temperature monitoring, structural health monitoring of dams, buildings and bridges, in MRI scanners for patient temperature monitoring, in railway monitoring systems, in a plethora of chemical detection applications or even in shape sensing applications for the oil and medical industry. But what makes optical fibres attractive as a sensing platform? The answer can be better understood by having a look at the structure, size, weight and materials of the optical fibre.
  </p>

                 <p>The picture above shows the structure of the most common type of optical fibre, the single core, single mode telecommunications optical fibre, the SMF-28e. The optical fibre is made of a glass core (made of fused silica and a small percentage of germanium oxide) and has a diameter of 8 μm, surrounded by a fused silica cladding (also made of glass) of 125 μm diameter and a protective acrylate jacket of 250 μm. The diameter of the functional part of the optical fiber (core + cladding) is comparable to that of a human hair (around 100 μm), making them extremely small in size and lightweight. Additionally, they are immune to electromagnetic interference, pose a high resistance to radiation and very high temperatures and can withstand immersion in chemically hostile environments. All these advantages, along with the many design and material variations of optical fibres, have made the optical fibre a very attractive platform for sensing applications. In the following picture, from left to right the design layout of a multi-core, a microstructured and a photonic bandgap optical fibre is presented, all of which exhibit unique properties and opportunities in optical fibre sensing.
</p>
                 <div className="col-md-14" style={{float:'left'}}>
              <div className="about-content-right">
                <figure>
                <img
                  src={image11}
                  style={{borderRadius:'10px',height:'150px',float:'left',marginTop:'10px',marginRight:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={image12}
                  style={{borderRadius:'10px',height:'150px',float:'left',marginTop:'10px',marginRight:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                 <img
                  src={image13}
                  style={{borderRadius:'10px',height:'150px',float:'left',marginTop:'10px',marginRight:'10px'}}
                  alt="about us"
                  className="img-fluid"
                /></figure>
                                  <figcaption style={{float:'left',width:'580px',fontSize:'10px',textAlign:'justify',top:'6px',position:'relative',color:'#095a4f'}}>Figure 2. From left to right: Multi-core optical fiber, microstructured optical fiber and photonic bandgap optical fiber.
</figcaption>

              </div>
            </div>
            <p>One of the most impressive optical fibre sensors is the shape sensing optical fibre. This optical fibre, as its name suggests, has the capability of providing optical information that can be used to reconstruct its shape at a user terminal (such as a personal computer).  The technology finds numerous applications from oil drilling (for surveillance and mapping of underground oil or gas reservoirs) to industrial robotic arm navigation and medicine. In the medical sector, one can find shape sensing optical fibres in catheters and endoscopes for navigation purposes, in robotic surgery for precise positioning, in MRI receiver coils for image optimization, as well as in orthopedic tool positioning, control and alignment. In the PALPABLE project, a set of shape sensing optical fibers is attached at the end of an endoscopic probe head, in order to reconstruct underlying surface shapes and acquire probe-to-target-tissue distance.</p>
            <div className="col-md-4" style={{float:'left'}}>
              <div className="about-content-right">
                <img
                  src={image14}
                  style={{borderRadius:'10px',height:'200px',float:'left',marginTop:'10px',marginRight:'0px'}}
                  alt="about us"
                  className="img-fluid"
                />
    <figcaption style={{fontSize:'10px',textAlign:'justify',marginLeft:'0px',top:'6px',position:'relative',color:'#095a4f'}}>Figure 3. The PALPABLE Project’s envisioned probe head. 3 shape sensing optical fibers act as the distance and surface mapping sensors in order to provide the necessary information for the determination of the soft tissue stiffness.</figcaption>

              </div>
            </div>
             <p>Ultimately, the aim of the project is the development of a miniature palpation sensor. If one simplifies the problem as the equivalent of finding the spring constant k of an unknown spring, according to the equation F = k·x, the optical fibre sensor is responsible for determining the distance x as the soft tissue (i.e. spring constant) is compressed towards the tool and another membrane sensor at the center of the probe is responsible for determining the force F applied from the tissue to the probe head. </p>
             <p>For the shape sensing itself, there are several technologies that are currently used to extract a meaningful optical signal for shape reconstruction from either multi-core or even standard single-core optical fibres. In multi-core optical fibres, one method to achieve shape sensing capabilities relies on Brillouin scattering and the other on Fiber Bragg Grating (FBG) sensors. In the case of Brillouin scattering based shape sensors, although a multi-core optical fibre without any post fabrication processing can be used, the required optical signal readout equipment is extremely expensive. On the other hand, shape reconstruction from FBG-based multicore optical fibers requires a less steep capital expenditure on the readout equipment, but requires non-trivial approaches in the fabrication of the sensing elements (the FBGs) on the individual cores of a multi-core (typically 4-core) optical fibres. For the case of embedded optical fibres, intensity modulation interrogation schemes can be utilized that provide an attractive low-cost alternative to the above methods. Additionally, femtosecond laser inscribed FBGs on standard single core optical fibres have also enabled shape sensing capabilities by means of eccentric FBGs (eFBGs) or cladding waveguide FBGs. In every case, if multi-core optical fibres are used, a major challenge lies in the fact that the cores (all residing within an area of less than 125 μm in diameter!) have to be individually interrogated. 
 </p>
             <div className="col-md-3" style={{float:'right'}}>
              <div className="about-content-right">
                <img
                  src={image15}
                  style={{borderRadius:'10px',height:'200px',float:'right',marginTop:'0px',marginRight:'0px'}}
                  alt="about us"
                  className="img-fluid"
                />
    <figcaption style={{fontSize:'10px',textAlign:'justify',marginLeft:'0px',top:'6px',position:'relative',color:'#095a4f'}}>Figure 4. Optical structure (optical manifold) developed for the convergence of the optical signals of a 4-core optical fibre towards the core of a single core optical fibre.</figcaption>

               
              </div>
            </div>
             <p>The PALPABLE Project aims to develop new methodologies in both the inscription of FBG sensors in multicore optical fibres, as well as in non-standard connection schemes between multicore optical fibres and the readout units. Additionally, the use of alternative optical fibre technologies for shape sensing will be explored. For the fabrication of FBGs, a novel setup is tested where a single phase mask (used to fabricate FBGs) is utilized for the inscription of all 4 individual FBGs in the shape sensing optical fibre. Additionally, a novel optical structure has been manufactured at the tip of a 4 core optical fibre in order to combine the signal of all 4 cores into the core of a single core optical fibre. With the above innovations project PALPABLE aims to significantly reduce the price and accessibility of the technology, to minimally invasive surgery and beyond.
</p>
<br/><p>Dr. Georgios Violakis<br/><span style={{fontStyle:'italic'}}>
Research Associate, Hellenic Mediterranean University</span></p>
              </div>
              <div className="about-content">
                <img
                  src={icon2}
                  style={{borderRadius:'10px',maxWidth:'600px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
