import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import eaes from "../../logos/photos/EAES.jpeg"
import eaes2 from "../../logos/photos/EAES2.jpeg"
import eaes3 from "../../logos/photos/EAES3.jpeg"
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';

export default function EAES() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_project_kicks_off_with_a_bang');
  }
  function handleClick2() {

    navigate('/');
  }
  return (
    <Layout>
      
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                The EAES congress in Rome embraces the PALPABLE M6 meeting
                 </h3>
             <p>On June 22-23rd, our second physical PALPABLE meeting occurred in Rome, marking six
                months since the project officially commenced. The meeting’s venue was the Roma Convention
                Center La Nuvola, where the 31st International EAES Congress took place at the same time.
                The congress lasted for four days (20-23 June 2023) and welcomed almost 1500 attendees
                from more than 80 countries. With 60 exhibitors, 70 scientific sessions and five hands-on
                courses, PALPABLE was in its natural habitat.</p>
              <p>Regarding the meeting itself, all partners (<a href='https://www.twi-hellas.com/' target="_blank">TWI Hellas</a>, 
              <a href='https://hmu.gr/en/home/' target="_blank"> Hellenic Mediterranean University</a>,
             <a href='https://www.medtronic.com/me-en/index.html?intcmp=mdt_com_country_selector_dropdown_atlasr22017' target="_blank"> Medtronic</a>,  
             <a href='https://bendabl.com/' target="_blank">Bendabl</a>, 
             <a href='https://en.unito.it/' target="_blank"> Università degli Studi di Torino</a>,  
             <a href='https://www.hhi.fraunhofer.de/en/departments/fs/research-groups/fiberlab.html' target="_blank"> Fraunhofer HHI</a>,
             <a href='https://eaes.eu/' target="_blank"> The European Association of Endoscopic Surgery</a>, 
             <a href='https://hmu.gr/en/home/' target="_blank"> Sofradim Production SASU</a>, 
             <a href='https://www.qmul.ac.uk/' target="_blank"> Queen Mary University of London</a>, 
             <a href='https://www.essex.ac.uk/' target="_blank"> University of Essex</a>) shared overviews of their progress. More specifically, the consortium discussed

             current achievements, ongoing developments, as well as future steps relevant to each partner’s
              expertise, from haptic sensor and photonic circuit design to soft robotics and pneumatic
              actuation systems. During the consortium gathering, a workshop was conducted with the EAES
              Task Force made up of esteemed surgeons specialising in minimally invasive surgery (MIS)
              from different medical specialities, who will be consulted throughout the project.</p>
                <p>Last but not least, the PALPABLE highlight at the EAES Congress was
                that our research initiative got to have its own banner hanging on one of
                the walls of the EAES stand, thanks to Arjan Appel, the EAES Executive
                Director. This significant occasion was the first of many to come for the
                project to showcase its technologies and overall mission.</p>
                <p>The month-6 meeting was successful the consortium members got the
                chance to align about the course of the project, attend the EAES
                Congress and explore innovations in the meditech industry. The next
                M12 meeting will be organised by Fraunhofer Heinrich-Hertz-Institut
                (FHHI) in late autumn 2023. Lastly, a big “thank you” to EAES for
                hosting the meeting and offering such an interesting experience.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={eaes}
                  style={{borderRadius:'10px',height:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={eaes2}
                  style={{borderRadius:'10px',height:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={eaes3}
                  style={{borderRadius:'10px',height:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
