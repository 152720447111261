import React from "react";
import { Timeline } from "react-twitter-widgets";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


export default function Contact({ bgColor }) {
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2 style={{color:'#12b59e'}}>Get in touch</h2>
                
              </div>
              <div className="footer-address">
               
                <ul>
               
                  <li>
                    <span>
                      Email :
                      <a href="mailto:hello@yourdomain.com" style={{color:'#12b59e'}}>
                      &nbsp; info@palpable-project.eu
                      </a>
                    </span>
                  </li>
                  <br></br>
                  <li>



                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7" id='contactdet' >
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
              >
                
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
/*
                  <Timeline
  dataSource={{
    sourceType: 'profile',
    screenName: 'palpableproject'
  }}
  options={{width:'300',height:'300'}}
/>
*/