import React from "react";

const Features = () => {
  return (
    <>
      <div className="overflow-hidden">
        <section style={{backgroundColor:'#095a4f'}} className="promo-section mt-5 ptb-100" id='solution'>
          <div className="container">
         

    
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h3 style={{color:'whitesmoke'}}>
                  The PALPABLE solution
                  </h3>
                <p style={{color:'whitesmoke'}}>PALPABLE intends to become a frontrunner in the European healthcare industry by making breakthroughs in photonic, multi-sensing systems and soft robotics devices. The project will focus on developing a palpation finger-like tool with a new instrument that can acquire, process and interpret vast amounts of sensory data, enable new functionalities, and be manufactured sustainably and with reduced cost. This way, the most accurate examination of tissues at different indentation forces becomes plausible through the integration of various technologies; a pneumatically actuated end-effector, a non-planar photonics circuit, a distance sensing module and a surface curvature and stiffness reconstruction algorithm that will render the tactile sensing probe sufficiently sensitive, flexible and small.
                </p>
                <p style={{color:'whitesmoke'}}>PALPABLE can become the perfect robotic ally for the European healthcare sector by… 
                </p>
                <ul className="list-unstyled" style={{color:'whitesmoke'}}>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">offering surgeons better access to visualising tissues and identifying their properties during minimally invasive surgeries (MIS) </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">ensuring a shorter and improved postoperative period for the patient
</p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">exploiting the manufacturing method and elements of the probe that can help the project scale up and make leaps into fields like agriculture and the food industry.</p>
                    </div>
                  </div>
                </li>
                </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="about-content-right">
                  <img
                    src="assets/img/image11.jpg"
                    style={{borderRadius:'20px'}}
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          
        </section>
        
      </div>
    </>
  );
};

export default Features;
