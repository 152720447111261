import React from "react";
import icon1 from "../../logos/photos/1719308219518.jpeg"
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image1 from "../../logos/photos/134.jpg"
import eaes2 from "../../logos/photos/EAES2.jpeg"
import eaes3 from "../../logos/photos/EAES3.jpeg"
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';

export default function EAES() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_project_kicks_off_with_a_bang');
  }
  function handleClick2() {

    navigate('/');
  }
  return (
    <Layout>
      
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                THE ROAD AHEAD FOR MINIMALLY INVASIVE THERAPY OF DIGESTIVE CANCERS
                 </h3>
             <p>I have dedicated all my entire scientific and clinical life to digestive cancer. With about 2
million new cases diagnosed every year in the world, colorectal cancer is one of the three
big killers. However, differently from lung and breast cancer, it increased its incidence by
35% in just the last six years, is expected to double in the next decade, and still with a
related mortality of about 50%. Similarly for esophageal and gastric cancers, whose global
incidence is 600,000 and 1,1 million per year, respectively.</p>
<p>To reduce mortality, we need mass endoscopic screening to detect lesions before they spread cells to other organs. At this stage, a simple polypectomy is curative, avoiding the need for major surgery or radio and chemotherapies. Every minute, 4 colorectal, as well as 2 gastric and 1 esophageal cancer cases are diagnosed worldwide, but ten times more “advanced polyps”.</p>
<div className="col-md-5" style={{float:'left'}}>
              <div className="about-content-right">
                <img
                  src={image1}
                  style={{borderRadius:'10px',height:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
<h5 style={{color:'#095a4f'}}>
PROGRESSES IN ENDOSCOPIC SURGERY
                 </h5>
                 <p>Two main concepts have characterised the last decades of the history of abdominal
surgery: reduction of invasiveness and technological innovation. Their primary expression
is the advent and the subsequent validation of laparoscopy as a safe and effective method
for performing almost all operations that were once only possible through a laparotomy. In
support of the application of minimally invasive techniques, robotic technology was
introduced in the late 90s. Robot-assisted surgery or robotic surgery are any types
of <a href='https://en.wikipedia.org/wiki/List_of_surgical_procedures'>surgical procedures</a> that are performed using <a href='https://en.wikipedia.org/wiki/Robot'>robotic</a> systems. Robotically assisted
surgery was developed to try to overcome the limitations of pre-existing <a href='https://en.wikipedia.org/wiki/Minimally_invasive_procedure'>minimally-invasive
surgical procedures</a> and to enhance the capabilities of surgeons performing open surgery.</p>
<p>Due to robotic use, the surgery is claimed to offer higher precision. Articulation beyond
normal manipulation and three-dimensional magnification help improve ergonomics.
Robotic technology is considered the way to increase the feasibility of any surgery under
endoscopic conditions, with related benefits, such as reduced duration of hospital stays,
blood loss, transfusions, and use of pain medication. The existing open surgery technique
has many flaws such as limited access to the surgical area, long recovery time, long hours
of operation, blood loss, surgical scars, and marks.</p>
<p>Nevertheless, endoscopic (and robotic) surgery is limited by the lack of direct palpable
feedback on manipulated tissues.</p>
<h5 style={{color:'#095a4f'}}>
ESOPHAGEAL AND GASTRIC CANCER
                 </h5>
                 <p>Unfortunately, esophageal, and gastric cancers have a high risk of lymph-node metastases already at very early stages. Moreover, they extend from the neck to the thorax, and the whole abdomen, from the hepatic pedicle to the spleen pedicle, including all para-aortic stations. This makes it particularly difficult to perform curative surgery. Surgeons are requested to perform extended resections dissecting all stations, based on the different risk of nodal involvement, but running the risk of leaving cells behind. Furthermore, the possible invasion of nearby organs is difficult to investigate. Endoscopic ultrasound probes are difficult to handle, rigid in shaft and return a very limited information until the most superficial layers, the air being contained in hallow organs, not traversable by ultrasound waves.</p>
<p>Assuming that AI can help us in the preoperative time to identify the extent of the tumour, the transfer of that information during surgery remains extremely complex. To progress in the way towards endoscopic therapy in digestive cancer, we need to address an <b>unmet clinical need:</b> restore the tactile ability to the operating surgeon.</p>
<h5 style={{color:'#095a4f'}}>
THE UNMET CLINICAL NEED – Need for palpation feedback.
                 </h5>
                 <p>We need new solutions to overcome the issues of current clinical practice. Suppose you ask me why we should use a robot in surgery? I am firmly convinced that the primary indication is to enable procedures of evident benefit for the patient, which are not possible today with existing endoscopic technology. </p>
                 <p>With respect to enabling palpation, many different approaches have been adopted to enhance current MIS tools. These include, but are not limited to, strain gauges on the surface of a flexible tripod structure, silicon-based sensors that utilise piezoresistive sensing and/or capacitive sensing and polymer-type sensors based on polyvinylidene fluoride (PVDF) sensing. The main drawbacks of these approaches are proneness to damage due to structure fragility, limited capabilities of distributed sensing, and extreme complexity with resulting high manufacturing costs. More recent approaches have used optical fibres which offer important advantages such as small size, light weight, insensitivity to electromagnetic interference, excellent biocompatibility, non-toxicity and high corrosion resistivity. Others include tactile sensors that work on intensity-modulated optical sensing that offer significant advantages such as simple demodulation requirements and low cost. </p>
                 <h5 style={{color:'#095a4f'}}>
CRUCIAL RESEARCH CHALLENGES FOR ENDOSCOPIC CURATIVE THERAPY </h5>
              <p>PALPABLE will advance the state of the art in photonics, soft robots, haptic & tactile sensing, sensor development, cost-effective manufacturing and sensor data fusion and will deliver a novel tactile sensing probe to be used as a palpation tool for identification and visualization of tissue abnormalities for use in Minimally Invasive Surgery. 
The introduction of a new medical tool for Minimally Invasive Surgery will have societal and economical impacts. Adding tactile feedback to the current armamentarium of the endoscopic surgeon with surely help to increase the rate of procedures performed in a minimally invasive way, taking advantage of existing technology that allows increased precision (and quality) in surgery.
</p>
                <p>Alberto Arezzo, General Secretary of the European Association for Endoscopic Surgery (EAES)</p>
              </div>
              <div className="about-content">
                <img
                  src={icon1}
                  style={{borderRadius:'10px',maxWidth:'600px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
