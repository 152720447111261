import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import Subsribe from "../components/newsletter/Subsribe";
import PageHeader from "../components/team/PageHeader";
import Teams from "../components/team/Teams";
import BrandCarousel from "../components/testimonial/BrandCarousel";
import MyPDF1 from './palpable-leaflet.pdf';
import MyPDF2 from './palpable-poster2.pdf';
import icon from './pdficon2.png'
import { Helmet,HelmetProvider} from 'react-helmet-async';


export default function Outcomes() {
  return (
    <>  <HelmetProvider>
    <Helmet>
  
     <meta property="og:title" content="Optical fibre sensors: From oil rigs to minimally invasive surgery"  />
     <meta property="title" content="Optical fibre sensors: From oil rigs to minimally invasive surgery"  />
     <meta name="description" content="Optical fibre sensors: From oil rigs to minimally invasive surgery"   />  
     <meta property="og:description" content="Optical fibre sensors: From oil rigs to minimally invasive surgery"  />
     <meta name="theme-color" content="#008f68" />
     </Helmet></HelmetProvider>
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Outcomes" Parent="Pages" PageTitle="Outcomes" />
      <section className="promo-section mt-5 ptb-100" id='deliverables'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#12b59e'}}>
            Public Deliverables
                  </h3>
            </div>
          </div>
   
        </div>
      </section>
      <section className="promo-section mt-5 ptb-100" style={{backgroundColor:'whitesmoke'}} id='publications'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#12b59e'}}>
            Publications
                  </h3>
             
            </div>
         
          
          </div>
   
        </div>
      </section>
      <section className="promo-section mt-5 ptb-100" id='material'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            <h3 style={{color:'#12b59e'}}>
            Promotional Material
                  </h3>
                  <ul style={{listStyle:'none'}}>
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a style={{color:'#12b59e'}}id='newsletter' href={MyPDF1} download="Palpable Leaflet.pdf">Palpable Leaflet <img style={{width:'22px',top:'8px',position:'relative'}} src={icon}></img></a>
              </li> 
    
              <li style={{textAlign:'left',minWidth:'500PX'}}>
              <a style={{color:'#12b59e'}} id='newsletter' href={MyPDF2} download="Palpable Poster.pdf">Palpable Poster <img style={{width:'22px',top:'8px',position:'relative'}} src={icon}></img></a>
              </li> 
          </ul>
            </div>
         
          
          </div>
   
        </div>
      </section>

      <Footer space />
    </Layout></>
  );
}
