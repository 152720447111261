import React from "react";
import AppRoutes from "./routes/Routes";
import PiwikPro from '@piwikpro/react-piwik-pro';

PiwikPro.initialize('bf2d731b-18f2-4f22-bdfb-29fa8e2dbb3a', 'https://pantheon-project.containers.piwik.pro');

const App = () => {
  return (
    <div>
      <AppRoutes />
    </div>
  );
};

export default App;
