import React from "react";

export default function PromoTwo() {
  return (
    <>
      <section className="promo-section mt-5 ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
              <div className="section-heading mb-5">
              
                <h3 className="mb-6" style={{color:'#12b59e'}}>
                PALPABLE in a nutshell
</h3>
              </div>
             
            </div>
         
          
          </div>
          <p>PALPABLE aspires to commence a new generation of palpation tools based on a synthesis of cutting-edge technologies essential for the identification and visualisation of tissue abnormalities in minimally invasive surgeries (MIS). By enhancing tactile sensing to achieve internal palpation, the method of tissue characterisation according to their depth, size and stiffness is also to be enhanced. This way, the consortium aims to further improve the current statistics regarding MIS advantages, from reduced tissue damage and blood loss during surgery to decreased postoperative recovery and hospitalisation.</p>
          <p>On top of the technological and medical advancements, the PALPABLE project aims to become an intrinsically cost-effective and ecological solution due to the biodegradable polymeric materials coupled with the detachable and disposable nature of the end effector and probe that deems sterilisation unnecessary.</p>
        </div>
      </section>
    </>
  );
}
