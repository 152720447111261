import React from "react";
import FeatureImg from "../components/features/FeatureImg";
import FeatureImgThree from "../components/features/FeatureImgThree";
import Features from "../components/features/Features";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import AllReview from "../components/testimonial/AllReview";

export default function Project() {
  return (
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Project" Parent="Pages" PageTitle="Project" />
      <section className="promo-section mt-5 ptb-100" id='problem'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
            
            <h3 className="mb-6" style={{color:'#12b59e'}}>
            Current Challenges
               </h3>
            </div>
          </div>
          <p>On a global level, 310 million major surgeries are performed yearly, with 20 million taking place in Europe. Over 2 million are laparoscopies, the most widespread minimally invasive surgery (MIS).
          Yet, current commercial surgical systems cannot grasp the sense of palpation, a key sensory operation in surgical procedures. They may be ideal for localised problems but cannot adequately perform if the operating area takes up a larger part of the patient’s body. Plus, these systems are not widely available due to their high cost, training requirements and safety concerns. 
          </p>
          <p>During minimally invasive surgeries (MIS), haptic, visual or tactile feedback is not always adequate. Combined with the lack of dexterity in surgical tools, operations could be time-consuming and with an increased possibility of accidental tissue damage, amongst other negative effects. So, by optimising the laparoscopic tools and processes, reduced invasiveness and operative time, as well as increased safety and functionality, could be achieved.
          </p>
          <p>
          Therefore, a deeper dive into soft robotics could be the answer to increased safety, dexterity and potentially reduced costs. For the above reasons, there is growing interest in developing pneumatically actuated soft robots combined with multiple other technologies that can release surgeries from existing constraints.
          </p>
          
        </div>
      </section>
      <Features id='solution'/>
      <FeatureImg id='plan'/>
      <Footer />
    </Layout>
  );
}
