import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";
import goslar from "../../logos/photos/glosar.jpg"


import eaes from "../../logos/photos/EAES.jpeg"
import eaes2 from "../../logos/photos/EAES2.jpeg"
import eaes3 from "../../logos/photos/EAES3.jpeg"
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';

export default function EAES() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_partners_meet_at_Fraunhofer_HHI_to_discuss_progress_and_future_plans');
  }
  function handleClick2() {

    navigate('/');
  }
  return (
    <Layout>
      
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                PALPABLE partners meet at Fraunhofer HHI to discuss progress and future plans
                 </h3>
             <p>At the end of November, Fraunhofer HHI welcomed PALPABLE partners to their premises in snowy Goslar to discuss progress and move forward on various lines of research at the M12 project meeting.</p>
             <p>The consortium discussed the review meeting recently held with the European Commission, which had brought much positive feedback, and assessed the project’s progress and KPIs in depth, to ensure all project work is on track.</p>
             <p>The team advanced on the integration of the different sensing elements within the palpation tool prototype and considered options for testing them, taking into account the robotics experts’ and surgeons’ perspectives.</p>
             <p>Partners not only discussed the progress and developments of the technology, but also its visibility and potential exploitation routes. A consultation with surgeons to develop the value proposition and the future of the technology after the project were on the table, and social media statistics were reviewed, which are happily on track to reach the planned indicators. </p>
             <p>During this in-person meeting of the project the group also had the opportunity to visit Fraunhofer HHI’s lab, and exchanged with the researchers about the technologies being used to develop their work on the photonics circuit .
</p> 
<p>If you are not already following us on social media, where in addition to project developments we also share the latest news from related sectors, you can find us on<a href='https://www.linkedin.com/showcase/palpable-project/'>LinkedIn</a>  and <a href='https://twitter.com/palpableproject'>X</a>.</p>
</div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={goslar}
                  style={{borderRadius:'10px',height:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
