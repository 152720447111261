import React from "react";
import img from "./10.png"

export default function FeatureImg() {
  return (
    <>
      <div id="plan" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <h3 style={{color:'#12b59e'}}>Project Plan
</h3>
               
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5 >Develop a thin, pneumatically actuated end-effector with proprioceptive sensing to…</h5>
                  <p className="mb-0">
                  - improve the freedom of motion and positioning accuracy of the probe.
                  </p>
                  <p className="mb-0">
                  - make the manufacturing of the tool cost-effective during the disposable/ sterilisable materials.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Develop a sensorised probe capable of distributed tactile sensing to…
</h5>
                  <p className="mb-0">
                    - distinguish between healthy and cancerous tissue.
                  </p>
                  <p className="mb-0">
                    - measure the membranes deformation sensitivity. 

                  </p>
                  <p className="mb-0">
                    - increase the sensor signal update rate.
                  </p>
                  <p className="mb-0">
                    - decrease probe manufacturing costs.
                  </p>
                </div>
              </div>
           
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img style={{borderRadius:'20px'}}
                  alt="placeholder"
                  src="assets/img/image20.jpg"
                  className="img-center img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Develop a non-planar photonics circuit for haptic sensor array interrogation to…
</h5>
                  <p className="mb-0">
                  - support different modes of waveguides.
                  </p>
                  <p className="mb-0">
                  - strengthen further the low-cost manufacturing strategy of the overall tool
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Develop a distance-sensing module to…</h5>
                  <p className="mb-0">
                    - provide a visual map of the respective organ under examination
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Develop a stiffness profile reconstruction algorithm to…</h5>
                  <p className="mb-0">
                    - reconstruct the tissue stiffness profile
                  </p>
                  <p className="mb-0">
                    - register the curvature of the palpated tissue region.

                  </p>
                  <p className="mb-0">
                    - render the above palpation information available to the surgeon comprehensibly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
