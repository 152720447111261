import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logo from './logo.png'
import xicon from '../footer/xicon2.svg'
import logo2 from './logo.png'
import {
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  NavItem,
  NavLink,
  Nav,
  Button,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${darkBg ? "bg-transparent" : "custom-nav white-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src={logo2}
                  width="140"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src={logo}
                  width="140"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                  <HashLink style={{marginTop:'4px'}} className="nav-link" smooth to='/#home'>
                    Home
                  </HashLink>
                </li>
                <li className="nav-item">
                      <UncontrolledDropdown >
  <DropdownToggle
    id='tet'>
    <HashLink className="nav-link" smooth to='/project'>
                    Project
                  </HashLink>
  </DropdownToggle>
  <DropdownMenu>
    <DropdownItem>
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/project#problem'>Current Challenges</HashLink>      
    </DropdownItem>
    <DropdownItem>
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/project#solution'>PALPABLE solution</HashLink>
    </DropdownItem>
    <DropdownItem >
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/project#plan'>Project plan</HashLink>
    </DropdownItem>
    
    
  </DropdownMenu>
  
</UncontrolledDropdown>
                </li>
                <li className="nav-item">
                  <HashLink style={{marginTop:'4px'}} className="nav-link" smooth to='/partners'>
                   Partners  </HashLink>
                </li>
                <li className="nav-item">
                <UncontrolledDropdown >
  <DropdownToggle
    id='tet'>
    <HashLink className="nav-link" smooth to='/news'>
                    Newsroom
                  </HashLink>
  </DropdownToggle>
  <DropdownMenu>
    <DropdownItem>
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/news#newses'>News</HashLink>      
    </DropdownItem>
    <DropdownItem>
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/news#blogposts'>Blogposts</HashLink>
    </DropdownItem>
    <DropdownItem>
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/news#newsletter'>Newsletter</HashLink>
    </DropdownItem>
  </DropdownMenu>
  
</UncontrolledDropdown>
                </li>
                <li className="nav-item">
                <UncontrolledDropdown >
  <DropdownToggle
    id='tet'>
    <HashLink className="nav-link" smooth to='/outcomes'>
                    Outcomes
                  </HashLink>
  </DropdownToggle>
  <DropdownMenu>
    <DropdownItem>
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/outcomes#deliverables'>Public Deliverables</HashLink>      
    </DropdownItem>
    <DropdownItem>
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/outcomes#publications'>Publications</HashLink>
    </DropdownItem>
    <DropdownItem >
              <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none', color:'#12b59e',margin:'auto'}} to='/outcomes#material'>Promotional Material</HashLink>
    </DropdownItem>
    
    
  </DropdownMenu>
  
</UncontrolledDropdown>
                </li>
                <li className="nav-item">
                  <HashLink style={{marginTop:'4px'}} className="nav-link" smooth to="/#contact">
                    Contact Us
                  </HashLink>
                </li>
                <li className="list-inline-item" style={{marginTop:'2px'}}>
                        <a href="https://www.linkedin.com/showcase/palpable-project/" target="_blank" title="Linkedin">
                          <span className="ti-linkedin"></span>
                        </a>
                      </li>
                     
                
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
