import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image12 from "../../logos/photos/meetingtask.png"
import eaes2 from "../../logos/photos/EAES2.jpeg"
import eaes3 from "../../logos/photos/EAES3.jpeg"
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';

export default function Maastricht() {
  const navigate = useNavigate();
 
  return (
    <Layout>
      
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                PALPABLE experts consult EAES surgeons in Maastricht
                 </h3>
             <p>This week PALPABLE experts met with the <a href='https://eaes.eu/'>European Association for Endoscopic Surgery’s</a> PALPABLE Task Force in the Netherlands, a group of surgeons specializing in Minimally Invasive Surgery (MIS) who consult on the development of the project. 
             </p>
              
   <p>The Task Force meeting, coordinated by Prof.<a href='https://www.albertoarezzo.it/'> Alberto Arezzo</a> from the <a href='https://en.unito.it/'>University of Turin</a> (UNITO),
     featured a presentation by Prof. <a href='https://sites.google.com/view/panagiotispolygerinos/'>Panos Polygerinos</a> from the 
     <a href='https://hmu.gr/'>Hellenic Mediterranean University</a> (HMU) on the latest advancements of the PALPABLE project and device. The presentation was followed by an in-depth discussion among the surgeons, focusing on both functional and technical aspects of the project. Topics ranged from usability concerns to technical implementation details, reflecting the surgeons' practical experiences and expertise.
   </p>
   <p>The insights and feedback gathered during this recurring meeting are crucial for the ongoing development of the PALPABLE device, as they ensure that by the end of the project, the outputs will be relevant to the needs of endoscopic surgeons, addressing major requirements that currently remain unmet by existing solutions.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={image12}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
