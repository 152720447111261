import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image1 from "../../logos/photos/image21.jpg"
import image2 from "../../logos/photos/image22.jpg"
import image3 from "../../logos/photos/image23.jpeg"

import { useNavigate } from 'react-router-dom';

export default function Hamlyn() {
  const navigate = useNavigate();
 
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                PALPABLE partners co-host workshop exploring the role of touch in surgery at Hamlyn Symposium
             </h3>
             <p>The recent workshop "From Virtual Palpation to Clinical Integration: Exploring the Role of Touch in Medicine and Surgery," held at the prestigious 
              <a href='https://www.hamlynsymposium.org/'> Hamlyn Symposium</a>, brought together leading experts and innovators from the PALPABLE consortium and beyond. It showcased the groundbreaking work of academia and industry and their contributions to the future of surgical robotics, with a focus on haptics and palpation, joining forces with the SoftHapSim project consortium.
             </p>
             <p>
19 different speakers shared their insights, and five students shared poster pitches. <a href='https://profiles.imperial.ac.uk/r.kneebone'>Prof. Roger Kneebone </a>from <a href='https://www.imperial.ac.uk/medicine/'>Imperial College London</a> gave the first presentation, inspiring participants with reflections on The Magic of Touch, relating the importance of touch for surgeons to that of magicians. He shared how touch in surgery is evolving quickly, from the procedural to the robotic fields.</p>
              <p>The presentation from <a href='https://www.albertoarezzo.it/'>Prof. Alberto Arezzo</a>, General Secretary of partner EAES included the key role computer vision has for the characterisation, identification and dissection of tumours, and  endoscopic signs for tumour characterisation.</p>
              <p>Partner experts <a href='https://www.qmul.ac.uk/eecs/people/profiles/althoeferkaspar.html'>Prof. Kaspar Althoefer</a> from <a href='https://www.qmul.ac.uk/'>Queen Mary University of London</a> and Zhenyu Zhang from <a href='https://www.hhi.fraunhofer.de/index.html'>Fraunhofer Heinrich-Hertz-Institut</a> shared PALPABLE research on tactile and stiffness sensors for diagnostic palpation and on the tactile sensor based on polymeric waveguides respectively.</p>
              <p>Dr. Stella Mavroveli and Dr. Dan Campioni-Norman from <a href='https://www.imperial.ac.uk/medicine/'>Imperial College London</a> spoke about simulation-based assessment of surgical skills, and how digital methods such as remote and automated assessment could address the segmentation of surgical training.</p>
              <p><a href='https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAB_8RjMBV0Q0EJ5jK7jBUCDKLkiJoS9ZdTI&keywords=ilias%20zournatzis&origin=RICH_QUERY_SUGGESTION&position=0&searchId=c1f9a0fe-a3ae-43e8-b96d-6242ff472a21&sid=MNp&spellCorrectionEnabled=false'>Ilias Zournatzis</a> from partner <a href='https://bendabl.com/'>Bendabl</a> focused on empowering surgeons, enhancing palpation and training in minimally invasive surgery. He highlighted their <a href='https://getboxlap.com/'>Boxlap</a> laparoscopic training kit that enables guided training of motor skills and hand-eye coordination.
                </p>
                
               <p>
               Dr. Yves Bayon from partner <a href='https://europe.medtronic.com/xd-en/index.html'>Medtronic</a> examined the clinical translation of palpable tissue devices; the impact of complying with standards and how sustainability can be ensured and ecodesign be implemented.
                </p> 
                <p>The other speakers also informed and inspired, adding to the food for thought for both project consortiums to consider in the months to come.</p>
                <p>Learn more about the programme and speakers <a href='https://www.hamlynsymposium.org/events/from-virtual-palpation-to-clinical-integration-exploring-the-role-of-touch-in-medicine-and-surgery/'>here</a>.</p>
                </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={image2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={image1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={image3}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
