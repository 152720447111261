import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"

export default function SubscribeForm() {
  const url = "https://gmail.us18.list-manage.com/subscribe/post?u=c9df0f816edea1f4d24fbbbdd&amp;id=26a0fe8f16&amp;f_id=00b92ce7f0";

const SimpleForm = () => <MailchimpSubscribe url={url}/>
  return (
    <>
      <section id="team" className="team-member-section">
        <div className="container">
        
            <div className="col-lg-7 col-md-8">
              
              <div className="section-heading text-center mb-5">
                
          
                <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (

      <div  id='simple' style={{padding:'40px',backgroundColor:'white',maxWidth:'400px',borderRadius:'20px'}}>
        <h5>Subscribe to our newsletter and stay updated.</h5>
        <SimpleForm  onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div style={{ color: "#1e646c",position:'absolute' }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" ,position:'absolute'}} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "#1e646c" }}>Subscribed !</div>}
      </div>
    )}
  />
              </div>
         

          </div>
        </div>
      </section>
    </>
  );
}
