import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import surgery from "../../logos/photos/surgery.jpg"

import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';

export default function EAES() {
  const navigate = useNavigate();
  
  return (
    <Layout>
      
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h5 style={{color:'#095a4f'}}>
                ADDRESSING A DRAWBACK OF MINIMALLY INVASIVE SURGERY
                 </h5>
                <p>The PALPABLE project introduces a tactile sensing device for use in Minimally Invasive Surgery
                (MIS). MIS offers several advantages over traditional open surgery, all associated with the
                minimisation of incision size. These generally include decreased risk of infection, fewer
                complications, speedier healing times, shorter hospital stays, and an overall less painful patient
                experience.</p>
                <p>However, a significant disadvantage of MIS is the lack of tactile feedback. In traditional open
                surgery, doctors often evaluate the health of organ tissue by manual palpation. In this manner,
                the operating surgeon can identify the presence, location and extent of tumorous tissue
                resulting in higher chances of a successful outcome.</p>
                </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={surgery}
                  style={{borderRadius:'10px',height:'250px'}}
                  alt="about us"
                  className="img-fluid"
                />
              
              </div>
            </div>
            <div className="col-md-12">
              <div className="about-content-left section-heading">
                <h5 style={{color:'#095a4f'}}>PALPABLE: INTRODUCING TACTILE FEEDBACK IN MIS</h5>
                <p>PALPABLE aims to combat this limitation by introducing a novel tool to supply tactile feedback
                in MIS. This is a challenging task to assign to a sensor: human tactile perception is a complex
                sense that engages several mechanoreceptors located at the hands, the output of which is
                localised in space via the sense of proprioception. Naturally, manual organ palpation relies on
                the dexterous interaction of the surgeons’ fingers with the palpated tissue. However,
                laparoscopic incision points (trocar ports) limit the reachable workspace of inserted solid tools.
                To overcome this issue in MIS, a palpation tool has to deform inside the patient’s body.</p>
                <p>Therefore, to achieve improved palpation, the main requirements for the PALPABLE tool’s
                design have been highlighted: we envision a multi-sensing palpation probe, hosting a variety of
                sensors that detect a wide range of tactile information to be used in stiffness evaluation of the
                palpated tissue. The probe will be inserted through a standardised trocar port, and steerable in
                a three-dimensional space within the patient’s body. The probe will be aware of its shape at
                each instant, and localise the tactile measurements on the targeted organ. Finally, the tactile
                measurements will be used to obtain an evaluation of the local tissue stiffness. The stiffness
                reconstruction will be communicated to the operating surgeon in the form of a heatmap: stiffer
                regions will be highlighted, indicating the location of tumorous tissue.</p>
                <h5 style={{color:'#095a4f'}}>THE CHALLENGES</h5>
                <p>The first challenge in conceptualising the PALPABLE tool is the definition of the tool’s tactile
                perception capabilities. The type and magnitude of stimuli to be detected by the PALPABLE
                tactile sensors depend on the properties of the palpated tissue in both its healthy and diseased
                variations. The stiffness evaluation method, which generally requires concurrent measurements
                for force and deformation, should yield precise results. Moreover, an important design task is
                the minimisation of the tool’s size: the whole tool should be inserted through a port under 15mm
                wide. The articulation of the probe within the patient’s body, as well as the localisation of the
                measurements, are highly significant for the tool’s successful operation. Last but not least, the
                probe, its components, and all the measurement principles shall not be harmful to the human
                body, while sustainability considerations shall be addressed.</p>
                <h5 style={{color:'#095a4f'}}>THE EXPERTS</h5>
                <p>The development of the PALPABLE tool requires expertise in several State-Of-The-Art (SOTA)
                technologies. The PALPABLE project has brought together a team of experts, covering a broad
                scientific spectrum from surgery and medical instrumentation to tactile sensing, optical circuits,
                readout systems, soft robotics, control and AI. In this series of posts, our experts will
                communicate interesting points related to their activities in PALPABLE. Stay tuned to find out
                how we overcome the design challenges and push forward the State-Of-The-Art technologies in
                MIS surgery!</p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
