import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image1 from "../../logos/photos/Image_20240627_55321_091.jpg"
import image2 from "../../logos/photos/Image_20240627_54641_465.jpg"
import image3 from "../../logos/photos/Image_20240627_60700_047.jpg"
import image4 from "../../logos/photos/Image_20240627_64012_318.jpg"


import { useNavigate } from 'react-router-dom';

export default function Queen() {
  const navigate = useNavigate();
 
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                PALPABLE consortium explores latest project advances at Queen Mary University 
             </h3>
             <p>PALPABLE partners came together in London from the 26th to 27th of June to discuss the latest research developments and plan for the coming months, hosted by <a href='https://www.qmul.ac.uk/' target="_blank"> Queen Mary University of London</a>.</p> 
             
             <p>All partners, <a href='https://www.twi-hellas.com/' target="_blank">TWI Hellas</a>, <a href='https://hmu.gr/en/home/' target="_blank"> Hellenic Mediterranean University</a>, 
             <a href='https://www.medtronic.com/me-en/index.html?intcmp=mdt_com_country_selector_dropdown_atlasr22017' target="_blank"> Medtronic</a>,  
             <a href='https://bendabl.com/' target="_blank">Bendabl</a>, 
             <a href='https://en.unito.it/' target="_blank"> Università degli Studi di Torino</a>,  
             <a href='https://www.hhi.fraunhofer.de/en/departments/fs/research-groups/fiberlab.html' target="_blank"> Fraunhofer HHI</a>,
             <a href='https://eaes.eu/' target="_blank"> The European Association of Endoscopic Surgery</a>, 
             <a href='https://hmu.gr/en/home/' target="_blank"> Sofradim Production SASU</a>, 
             <a href='https://www.qmul.ac.uk/' target="_blank"> Queen Mary University of London</a>, 
             <a href='https://www.essex.ac.uk/' target="_blank"> University of Essex</a> gave an overview of their progress for internal review. Discussions included the first iteration of the probe design,  the fabrication of the soft steering mechanism, and the development of the PALPABLE optical sensors.
             </p>
              <p>An integration workshop gave partners the opportunity to plan ahead, towards an integrated prototype that will be developed in the following months. Time was also spent planning the upcoming review meeting and fine tuning the details of the M18 report, as well as how to ensure the most effective communication and dissemination activities. </p>
              <p>Participants also had the opportunity to visit QMUL’s Centre for Advanced Robotics, a state-of-the-art robotics lab for teaching and research, and discuss with the researchers on soft robotics and stiffness sensing. The lab is equipped with the latest robotic systems and specialised equipment, including medical robotics devices, soft robots, fabrication systems, sensor manufacturing and testing equipment. The visiting partners also had the opportunity to attend a live demonstration of the QMUL sensing device, which sparked conversations on module interfacing and device integration.
</p>
<p>The gathering further built the collaborative spirit of the PALPABLE project, with partners leaving the meeting inspired and ready to advance their innovative research and development.</p>
              
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={image1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                  <img
                  src={image2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right" style={{display:'flex', alignItems:'center'}}>
                <img
                  src={image4}
                  style={{borderRadius:'10px',width:'210px'}}
                  alt="about us"
                  className="img-fluid"
                />
                  <img
                  src={image3}
                  style={{borderRadius:'10px',marginLeft:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        
      </section>
  

      <Footer space />
    </Layout>
  );
}
