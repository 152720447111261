import React from "react";


export default function AboutApp() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
        <h3 style={{color:'#12b59e'}}>Consortium</h3>
              <p className="mb-4 lh-190">
              The PALPABLE project has been inspired and will be developed through its four-year lifetime by a transnational consortium of multiple partners.
              </p>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              
              
              <ul className="list-unstyled">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/greece.svg" style={{border:'2px solid #095a4f',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                        Greece is represented by TWI Hellas (THL), the project coordinator, the Hellenic Mediterranean University (HMU) and Bendabl (BNDL).
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div >
                        <img src="assets/img/spain.svg" style={{border:'2px solid #095a4f',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      Spain is represented by Medtronic Iberica (MEDT). 
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/italy.svg" style={{border:'2px solid #095a4f',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0"  style={{ marginLeft:'20px'}}>
                      Italy is represented by the University of Turin (UNITO).
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/netherlands.svg" style={{border:'2px solid #095a4f',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      The Netherlands is represented by The European Association of Endoscopic Surgery (EAES). 
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div>
                        <img src="assets/img/germany.svg" style={{border:'2px solid #095a4f',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      Germany is represented by Fraunhofer Heinrich Hertz Institute (FRHHI). 
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/france.svg" style={{border:'2px solid #095a4f',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      France is represented by Sofradim Production (SOF). 
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/uk.svg" style={{border:'2px solid #095a4f',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      The United Kingdom is represented by the Queen Mary University of London (QMUL) and the University of Essex (UESS).                    </p>
                    </div>
                  </div>
                </li>
              
              </ul>
            </div>
            <div className="col-md-6">
              <div className="about-content-right">
                <img
                  src="assets/img/unnamed.jpg"
                  width="500"
                  alt="about us"
                  style={{borderRadius:'20px'}}
                  className="img-fluid"
                />
              </div>
              
            </div>
          
         
          </div>
          
        </div>
      </section>
    </>
  );
}
