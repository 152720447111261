import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import kickoff from "../../logos/photos/kickoff.jpg"

import { useNavigate } from 'react-router-dom';

export default function Kickoff() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_project_kicks_off_with_a_bang');
  }
  function handleClick2() {

    navigate('/');
  }
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                PALPABLE project kicks off with a bang
             </h3>
             <p>The 14th-15th of February 2023 found the PALPABLE partners (<a href='https://hmu.gr/en/home/' target="_blank">Hellenic Mediterranean University</a> ,
             <a href='https://www.medtronic.com/me-en/index.html?intcmp=mdt_com_country_selector_dropdown_atlasr22017' target="_blank"> Medtronic</a>,  
             <a href='https://bendabl.com/' target="_blank">Bendabl</a>, 
             <a href='https://en.unito.it/' target="_blank"> Università degli Studi di Torino</a>,  
             <a href='https://www.hhi.fraunhofer.de/en/departments/fs/research-groups/fiberlab.html' target="_blank"> Fraunhofer HHI</a>,
             <a href='https://eaes.eu/' target="_blank"> The European Association of Endoscopic Surgery</a>, 
             <a href='https://hmu.gr/en/home/' target="_blank"> Sofradim Production SASU</a>, 
             <a href='https://www.qmul.ac.uk/' target="_blank"> Queen Mary University of London</a>, 
             <a href='https://www.essex.ac.uk/' target="_blank"> University of Essex</a>) gathered in Athens for the project 
              kick-off meeting organised by  <a href='https://www.twi-hellas.com/' target="_blank">TWI Hellas</a>. This occasion marks the beginning of a technological journey into Minimally Invasive Surgery with travellers with vast 
              experience in soft robotics design, non-linear pneumatic control and multiple sensing modalities, continuum mechanics, haptic sensor and probe design, 
              stiffness reconstruction algorithms, and distance sensing modules. The mission is to merge all the above technologies to bring a multi-sensing tool for Minimally 
              Invasive Surgery to the Meditech industry.</p>
              <p>The multinational, multidisciplinary consortium members had the chance to get to know each other, present their organisations, institutions and companies and work. 
                Additionally, initial technical planning, discussion of the following steps as well as the focal project points.</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={kickoff}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
